<template>
      <div class="loadbox flex-align-center">
            <div class="box">
                  <figure>
                        <section>
                              <div></div>
                        </section>
                        <section>
                              <div></div>
                        </section>
                        <section>
                              <div></div>
                        </section>
                        <section>
                              <div></div>
                        </section>
                        <section>
                              <div></div>
                        </section>
                        <section>
                              <div></div>
                        </section>
                        <section>
                              <div></div>
                        </section>
                        <section>
                              <div></div>
                        </section>
                  </figure>
            </div>
      </div>
</template>

<script>
      export default {

      }
</script>

<style>
      .loadbox{
           width: 100vw;
           height: 100vh;
           background-color: #fff;
      }
      @keyframes move {
            from {
                  transform: translate(0, 50%);
            }

            to {
                  transform: translate(0, 850%);
            }
      }

      * {
            margin: 0;
            padding: 0;
      }

      figure {
            margin: 30px;
            width: 250px;
            height: 250px;
            position: relative;
      }

      section {
            width: 2.5%;
            height: 25%;
            position: absolute;
            left: 45%;
      }

      section:nth-child(2) {
            transform: rotate(22.5deg);
      }

      section:nth-child(3) {
            transform: rotate(45deg);
      }

      section:nth-child(4) {
            transform: rotate(67.5deg);
      }

      section:nth-child(5) {
            transform: rotate(90deg);
      }

      section:nth-child(6) {
            transform: rotate(112.5deg);
      }

      section:nth-child(7) {
            transform: rotate(135deg);
      }

      section:nth-child(8) {
            transform: rotate(157.5deg);
      }

      figure div {
            height: 10%;
            border-radius: 50%;
            background: #449586;
            animation: move 1s ease-in-out infinite alternate;
      }

      figure:nth-child(1)>section:nth-child(1)>div {
            animation-delay: -0.5s;
      }

      figure:nth-child(1)>section:nth-child(3)>div {
            animation-delay: -0.5s;
      }

      figure:nth-child(1)>section:nth-child(5)>div {
            animation-delay: -0.5s;
      }

      figure:nth-child(1)>section:nth-child(7)>div {
            animation-delay: -0.5s;
      }
</style>